@import url("https://fonts.googleapis.com/css2?family=Inconsolata&family=Lobster&display=swap");

.card {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
  margin-bottom: 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
  width: 400px;
  margin: auto;
  min-height: 300px;
}

@media (max-width: 600px) {
  .card {
    width: 80vw;
  }
}

.translation {
  display: flex;
  margin: auto;
  flex-direction: column;
  width: 100%;
}

.heading {
  font-family: "Inconsolata", monospace;
  font-size: 15px;
  text-align: center;
}
.word {
  font-family: "Lobster", cursive;
  font-size: 35px;
  text-align: center;
  max-width: 90%;
  word-wrap: break-word;
}

.english {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  .heading {
    margin-bottom: 15px;
  }

  .word {
    display: flex;
    flex-direction: row;
    color: #008c45;
    margin: auto;
  }
}

.italian {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  min-height: 100px;

  .word {
    display: flex;
    flex-direction: row;
    color: #cd212a;
    margin: auto;
    transition: all 20ms linear;
    margin: 21px auto auto auto;
  }
}

.revealButton {
  border-radius: 20px;
  padding: 5px 15px;
  background-color: lightgrey;
  color: black;
  display: flex;
  margin: 25px auto auto auto;
  min-width: 90px;
  text-align: center;
  align-content: center;
  justify-content: center;
  transition: visibility 50ms linear;
  padding: 10px 20px;
  cursor: pointer;
}

@media only screen and (orientation: landscape) and (max-device-width: 480px) {
  .card {
    margin-top: 2vh;
    min-height: 70vh;
  }
}
