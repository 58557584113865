.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

.card {
  margin-top: 20vh;
  min-height: 55vh;
  transition: all 0.3s ease-in-out;
}

.buttons {
  display: flex;
  justify-self: center;
  align-self: center;
  flex-direction: row;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.4);

  .back,
  .forward {
    cursor: pointer;
  }

  .back,
  .forward {
    img {
      padding-bottom: 20px;
      display: flex;
      display: flex;
      align-self: center;
      margin-top: 25px;
    }
  }

  .back {
    display: flex;
    margin-left: 10vw;
    margin-right: 10vw;
  }
  .forward {
    display: flex;
    margin-left: 10vw;
    margin-right: 10vw;
  }
}

.revealToggle {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  padding: 5px 15px;
  border-radius: 20px;
  margin: 5px;
  font-size: 12px;
  cursor: pointer;

  .toggleText {
    margin: auto;
  }
}

@media only screen and (orientation: landscape) and (max-device-width: 900px) {
  .card {
    margin-top: 4vh !important;
  }

  .buttons {
    height: 16vh;
    margin-bottom: 2vh;
  }
}
