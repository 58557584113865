html,
body {
  height: 100vh;
  width: 100vw;
  padding: 0px;
  margin: 0px;
  font-family: "Helvetica", "Paralucent";
  overscroll-behavior: none;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

body:before {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: -10;
  background: url("./assets/italy.jpg") no-repeat center center !important;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
}
